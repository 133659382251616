// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-auseinandersetzung-jsx": () => import("./../../../src/pages/auseinandersetzung.jsx" /* webpackChunkName: "component---src-pages-auseinandersetzung-jsx" */),
  "component---src-pages-bereiche-jsx": () => import("./../../../src/pages/bereiche.jsx" /* webpackChunkName: "component---src-pages-bereiche-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-details-jsx": () => import("./../../../src/pages/details.jsx" /* webpackChunkName: "component---src-pages-details-jsx" */),
  "component---src-pages-erbfolge-jsx": () => import("./../../../src/pages/erbfolge.jsx" /* webpackChunkName: "component---src-pages-erbfolge-jsx" */),
  "component---src-pages-erbschein-jsx": () => import("./../../../src/pages/erbschein.jsx" /* webpackChunkName: "component---src-pages-erbschein-jsx" */),
  "component---src-pages-immobilienrecht-jsx": () => import("./../../../src/pages/immobilienrecht.jsx" /* webpackChunkName: "component---src-pages-immobilienrecht-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-informationen-jsx": () => import("./../../../src/pages/informationen.jsx" /* webpackChunkName: "component---src-pages-informationen-jsx" */),
  "component---src-pages-kanzlei-jsx": () => import("./../../../src/pages/kanzlei.jsx" /* webpackChunkName: "component---src-pages-kanzlei-jsx" */),
  "component---src-pages-nachlassgericht-jsx": () => import("./../../../src/pages/nachlassgericht.jsx" /* webpackChunkName: "component---src-pages-nachlassgericht-jsx" */),
  "component---src-pages-pflichtteil-jsx": () => import("./../../../src/pages/pflichtteil.jsx" /* webpackChunkName: "component---src-pages-pflichtteil-jsx" */),
  "component---src-pages-uebergabe-jsx": () => import("./../../../src/pages/uebergabe.jsx" /* webpackChunkName: "component---src-pages-uebergabe-jsx" */),
  "component---src-pages-vorsorge-jsx": () => import("./../../../src/pages/vorsorge.jsx" /* webpackChunkName: "component---src-pages-vorsorge-jsx" */)
}

